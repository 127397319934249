import React, { useCallback, useMemo } from 'react';
import Image from 'next/image';
import { Button, Card, IconButton, Stack, Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import { PRODUCT_TUTORIALS } from 'common/constants';
import { PlayIcon } from 'components/Icons/PlayIcon';
import { SurflineWaterDrop } from 'components/Icons/SurflineWaterDrop';
import Information, { SegmentProperties } from 'components/Information';
import getMapImageSrc from 'utils/urls/mapTilerPath';

// @ts-ignore Image can't be found on this path
import paywallForecasters from '../../../public/images/paywalls/forecaster-content-forecasters.png';

import styles from './SubregionForecastPaywalls.module.scss';

export const SubregionForecastContentPaywallDialogFooter: React.FC<{
  segmentProps: SegmentProperties;
  upgradeUrl: string;
}> = ({ segmentProps, upgradeUrl }) => {
  const onClickHandler = useCallback(() => {
    trackEvent('Clicked Subscribe CTA', {
      ...segmentProps,
      location: 'forecast content paywall video footer',
      modalType: 'paywall',
    });
  }, [segmentProps]);

  return (
    <div className={styles.dialogCtaWrapper}>
      <Button
        className={styles.dialogCtaButton}
        data-testid="start-free-trial-button"
        disableFocusRipple
        disableRipple
        disableTouchRipple
        href={upgradeUrl}
        onClick={onClickHandler}
        variant="secondary"
      >
        Start free trial
      </Button>
    </div>
  );
};

/* eslint-disable import/prefer-default-export */
export const SubregionForecastContentPaywall: React.FC<{
  geo?: { lat?: number; lon?: number };
  isNative?: boolean;
  segmentProperties?: SegmentProperties;
  upgradeUrl: string;
}> = ({ geo, isNative, segmentProperties, upgradeUrl }) => {
  const backgroundStyles = useMemo(() => {
    const mapUrl = getMapImageSrc({
      geo,
      mapHeight: 232,
      mapID: 'satellite',
      mapWidth: 412,
      mapZoom: 7.25,
    });
    return { backgroundImage: `url(${mapUrl})` };
  }, [geo]);

  const handleOnClickCTA = useCallback(() => {
    if (segmentProperties) {
      trackEvent('Clicked Subscribe CTA', {
        ...segmentProperties,
        location: 'forecast content paywall trial',
      });
    }
  }, [segmentProperties]);

  const segmentPropsVideoModal = useMemo(
    () => ({
      ...segmentProperties,
      location: 'Forecast Content Video',
      modalType: 'paywall',
    }),
    [segmentProperties],
  );

  return (
    <Card className={styles.card} data-testid="subregion-forecast-content-paywall">
      <Stack direction="row" spacing={2} mb={2} justifyContent="center" alignItems="center">
        <div>
          <SurflineWaterDrop className={styles.paywallLogo} />
        </div>
        <div>
          <Image
            alt="Surfline Forecasters"
            height="32"
            src={paywallForecasters}
            unoptimized
            width="128"
          />
        </div>
      </Stack>
      <Typography component="h3" variant="title3" mb={2}>
        Receive expert human guidance with Premium.
      </Typography>
      <Typography component="p" variant="footnote" mb={4} sx={{ lineHeight: '1.3em' }}>
        Daily reports and short term outlooks.
        <br />
        Days to watch and why.
      </Typography>
      <Button
        className={styles.upgradeButton}
        href={upgradeUrl}
        size="large"
        variant="primary"
        data-testid="subregion-forecast-upgrade-button"
        onClick={handleOnClickCTA}
      >
        Start free trial
      </Button>
      <Information
        DialogFooter={
          <SubregionForecastContentPaywallDialogFooter
            segmentProps={segmentPropsVideoModal}
            upgradeUrl={upgradeUrl}
          />
        }
        segmentProps={segmentProperties}
        articleId={PRODUCT_TUTORIALS.articles.paywallForecasterContent.id}
        isNative={isNative}
        theme="cinematic"
      >
        <IconButton
          aria-label="Play video"
          className={styles.playButton}
          data-testid="subregion-forecast-paywall-video-button"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          style={backgroundStyles}
        >
          <PlayIcon />
        </IconButton>
      </Information>
      <Typography component="p" variant="footnote" sx={{ lineHeight: '1.3em' }}>
        See how it works.
      </Typography>
    </Card>
  );
};
