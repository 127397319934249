import { TreatmentWithConfig } from '@surfline/web-common';
import {
  PREMIUM_FORECAST_DAYS,
  PREMIUM_FORECAST_DAYS_CHARTS,
  NON_PREMIUM_FORECAST_DAYS,
  NON_PREMIUM_FORECAST_DAYS_V3,
} from 'common/constants';
import { SL_WEB_FREEMIUM_V3_FORECAST_DAYS } from 'common/treatments';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useTreatments } from './treatments';

type ForecastDaysType = 'home' | 'spot' | 'chart' | 'favorites' | 'forecast-content';

export const forecastDaysAllowed = ({
  type,
  isEntitled,
  freemiumTreatment,
  customDays,
  query,
}: {
  type: ForecastDaysType;
  isEntitled: boolean | undefined;
  freemiumTreatment: string | TreatmentWithConfig;
  customDays?: number;
  query?: Record<string, string | string[] | undefined>;
}) => {
  const isNative = query?.native?.toString() === 'true';
  const nativeForecastDays = query?.forecastDays ? Number(query.forecastDays) : null;

  if (isEntitled) {
    if (type === 'chart') {
      return PREMIUM_FORECAST_DAYS_CHARTS;
    }

    return PREMIUM_FORECAST_DAYS;
  }

  if (
    isNative &&
    nativeForecastDays &&
    nativeForecastDays >= 5 &&
    nativeForecastDays <= 10 &&
    type === 'forecast-content'
  ) {
    return nativeForecastDays;
  }

  if (customDays && customDays > 0) {
    return customDays;
  }

  if (freemiumTreatment === 'on') {
    return NON_PREMIUM_FORECAST_DAYS_V3;
  }

  return NON_PREMIUM_FORECAST_DAYS;
};

export const useForecastDaysAllowed = ({
  type,
  isEntitled,
  customDays,
}: {
  type: ForecastDaysType;
  isEntitled: boolean | undefined;
  customDays?: number;
}) => {
  const router = useRouter();
  const { query } = router;
  const treatments = useTreatments();
  const freemiumTreatment = treatments[SL_WEB_FREEMIUM_V3_FORECAST_DAYS];

  const days = useMemo(
    () =>
      forecastDaysAllowed({
        type,
        isEntitled,
        freemiumTreatment,
        customDays,
        query,
      }),
    [customDays, freemiumTreatment, isEntitled, query, type],
  );

  return {
    forecastDaysAllowed: days,
  };
};

export default forecastDaysAllowed;
