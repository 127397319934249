import React, { Fragment, useMemo } from 'react';
import classNames from 'classnames';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { useRouter } from 'next/router';
import { useMount } from 'react-use';
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { getUserFavorites, trackNavigatedToPage, getUserSettings } from '@surfline/web-common';

import { PRODUCT_TUTORIALS, DMY } from 'common/constants';
import PadlockIcon from 'components/Icons/Padlock';
import StarCircle from 'components/Icons/StarCircle';
import Information from 'components/Information';
import PremiumAnalysisMeta from 'components/PremiumAnalysisMeta';
import SubregionForecastBackground from 'components/SubregionForecastBackground';
import SubregionForecastButtonList from 'components/SubregionForecastButtonList';
import SubregionForecastCard from 'components/SubregionForecastCard';
import SubregionForecaster from 'components/SubregionForecaster';
import SubregionForecastHighlights from 'components/SubregionForecastHighlights';
import {
  SubregionForecastContentPaywall,
  SubregionForecastContentPaywallDialogFooter,
} from 'components/SubregionForecastPaywalls';
import WavetrakLink from 'components/WavetrakLink';
import useAnalysisPageScroll from 'hooks/useAnalysisPageScroll';
import { useAppSelector } from 'stores/hooks';
import { getSpotDetails } from 'selectors/spot';
import {
  getFavoriteSubregions,
  getForecastContent,
  getForecastContentLoading,
  getSubregionLoading,
  getSubregionOverviewData,
} from 'selectors/subregion';
import { useUserPermissionStatus } from 'selectors/user';
import type { FavoriteItem } from 'types/favorites';
import type { SpotDetails } from 'types/spot';
import type { FavoriteSubregions, ForecastContent, SubregionOverviewData } from 'types/subregion';
import { isAfterGivenHour } from 'utils/time';
import { spotReportPath } from 'utils/urls';

import styles from './SubregionForecast.module.scss';

const getDayOfWeek = (dateString: string, dateFormat: string): string => {
  const timezone = 'UTC';
  const selectedDate = zonedTimeToUtc(`${dateString}T00:00:00`, timezone);
  const dayName = formatInTimeZone(selectedDate, timezone, 'EEE');
  const dayNumber = formatInTimeZone(selectedDate, timezone, dateFormat);
  return `${dayName} ${dayNumber}`;
};

// Default geo location of HB Northside for the background map
const defaultGeo = {
  lat: 33.65574085629463,
  lon: -118.00397,
};

const SubregionForecast: React.FC<{
  isSubregionListVisible?: boolean;
  scrollContainerId?: string;
}> = ({ isSubregionListVisible, scrollContainerId }) => {
  const { query } = useRouter();
  const subregionOverview: SubregionOverviewData = useAppSelector(getSubregionOverviewData);
  const isSubregionLoading: boolean = useAppSelector(getSubregionLoading);
  const isForecastContentLoading: boolean = useAppSelector(getForecastContentLoading);
  const isLoading: boolean = isSubregionLoading || isForecastContentLoading;
  const spot: SpotDetails = useAppSelector(getSpotDetails);
  const favorites: FavoriteItem[] = useAppSelector(getUserFavorites);
  const favoriteSubregions: FavoriteSubregions = useAppSelector(getFavoriteSubregions);
  const forecastContent: ForecastContent = useAppSelector(getForecastContent);
  const { hasPremiumAnalysisPermissions } = useUserPermissionStatus();
  const userSettings = useAppSelector(getUserSettings);

  useAnalysisPageScroll({
    containerId: scrollContainerId,
    today: forecastContent?.today.date,
  });

  const isNative = query?.native?.toString() === 'true';
  const spotId = query?.spotId?.toString() ?? subregionOverview?.primarySpot;
  const showShareIcons = query?.showShareIcons?.toString() === 'true';
  const upgradeUrl = isNative ? 'surfline://upgrade' : '/upgrade';
  const activeSubregion = forecastContent?.subregion.id ?? subregionOverview?._id;
  const dateFormat = userSettings?.date?.format === DMY ? 'd/M' : 'M/d';

  const filteredSubregions = useMemo(
    () => favoriteSubregions?.filter((subregion) => subregion.forecastStatus.status === 'active'),
    [favoriteSubregions],
  );

  const isAfter2pm = useMemo(() => {
    if (!forecastContent?.timezone) return false;
    return isAfterGivenHour(forecastContent?.timezone, 14);
  }, [forecastContent]);

  const primarySpot = useMemo(
    () => subregionOverview?.spots.find((s) => s._id === spotId),
    [spotId, subregionOverview],
  );

  const geo = useMemo(
    () => ({ lat: forecastContent?.location.lat, lon: forecastContent?.location.lon }),
    [forecastContent?.location],
  );

  const highlights = useMemo(
    () =>
      forecastContent?.highlights.map((day, index) => {
        let dayToWatch = day.forecast !== null;
        if (index === 0 && forecastContent?.today.forecast.dayToWatch) {
          dayToWatch = true;
        } else if (index === 1 && forecastContent?.tomorrow.forecast.dayToWatch) {
          dayToWatch = true;
        }
        return {
          date: day.date,
          dayToWatch,
        };
      }),
    [forecastContent],
  );

  const forecastHighlights = useMemo(
    () => forecastContent?.highlights.filter((day) => day.forecast !== null) ?? [],
    [forecastContent],
  );

  const todayDate = useMemo(
    () =>
      forecastContent?.today.date ? new Date(`${forecastContent.today.date}T00:00:00`) : undefined,
    [forecastContent?.today.date],
  );

  const tomorrowDate = useMemo(
    () =>
      forecastContent?.tomorrow.date
        ? new Date(`${forecastContent?.tomorrow.date}T00:00:00`)
        : undefined,
    [forecastContent?.tomorrow.date],
  );

  const lastUpdate = useMemo(
    () =>
      forecastContent?.lastPublished ? new Date(forecastContent.lastPublished * 1000) : undefined,
    [forecastContent?.lastPublished],
  );

  const spots = useMemo(() => {
    if (isNative) return null;
    const spotsInFavorites = subregionOverview?.spots?.filter((s) =>
      favorites.find((f) => f._id === s._id),
    );
    return spotsInFavorites?.length ? (
      <Box className={styles.scrollXWrapper} mt={2}>
        <ul className={styles.linkList}>
          {spotsInFavorites?.map((s) => {
            const href = spotReportPath({}, { _id: s._id, name: s.name });
            return (
              <li key={s._id}>
                <Button
                  color="dark"
                  component={WavetrakLink}
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  href={href}
                  size="small"
                  variant="primary"
                >
                  {s.name}
                </Button>
              </li>
            );
          })}
        </ul>
      </Box>
    ) : null;
  }, [favorites, isNative, subregionOverview]);

  const segmentPropsLock = useMemo(
    () => ({
      location: 'forecast content lock',
      modalType: 'paywall',
      spotId: spot?._id,
      spotName: spot?.name,
      subregionId: forecastContent?.subregion.id,
      subregionName: forecastContent?.subregion.name,
    }),
    [forecastContent, spot],
  );

  const highlightsContent = useMemo(
    () =>
      forecastHighlights.map((day, index) => {
        const isPaywallDayUnlocked =
          index === forecastHighlights.length - 1 && forecastHighlights.length > 1;
        return (
          <Fragment key={`day-${day.date}`}>
            <Grid id={`forecast-day-${day.date}`} item mobile={12} tablet={12}>
              <Stack alignItems="center" direction="row" mb={1} spacing={2}>
                <Chip
                  className={styles.highlightsDateChip}
                  data-testid="subregion-forecast-date-chip"
                  label={getDayOfWeek(day.date, dateFormat)}
                  size="small"
                  variant="filled"
                />
                {!hasPremiumAnalysisPermissions && (
                  <Information
                    DialogFooter={
                      <SubregionForecastContentPaywallDialogFooter
                        segmentProps={segmentPropsLock}
                        upgradeUrl={upgradeUrl}
                      />
                    }
                    articleId={PRODUCT_TUTORIALS.articles.paywallForecasterContent.id}
                    isNative={isNative}
                    theme="cinematic"
                  >
                    <IconButton
                      aria-label="Premium content is locked. Upgrade to see it now."
                      className={classNames({
                        [styles.paywallLockButton]: true,
                        [styles.paywallLockButtonUnlocked]: isPaywallDayUnlocked,
                      })}
                      data-testid="close-button"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                    >
                      <PadlockIcon locked={!isPaywallDayUnlocked} />
                    </IconButton>
                  </Information>
                )}
              </Stack>
              {(hasPremiumAnalysisPermissions || isPaywallDayUnlocked) && (
                <Typography component="h4" variant="callout1" className={styles.cardTextHeadline}>
                  {day.forecast?.headline}
                </Typography>
              )}
              {hasPremiumAnalysisPermissions && day.forecast?.observation && (
                <Typography component="div" variant="footnote" className={styles.cardText}>
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: day.forecast.observation,
                    }}
                  />
                </Typography>
              )}
            </Grid>
          </Fragment>
        );
      }),
    [
      dateFormat,
      forecastHighlights,
      hasPremiumAnalysisPermissions,
      isNative,
      segmentPropsLock,
      upgradeUrl,
    ],
  );

  useMount(() => {
    trackNavigatedToPage('Premium Analysis', {
      category: 'forecast',
      channel: 'forecast',
      human:
        forecastContent?.forecaster &&
        (forecastContent?.today || forecastContent?.tomorrow || forecastContent?.highlights.length),
      native: isNative,
      subregionId: forecastContent?.subregion.id,
      subregionName: forecastContent?.subregion.name,
      viewedLocation: 'regional',
    });
  });

  return (
    <Box className={classNames({ [styles.wrapper]: true, [styles.isNative]: isNative })}>
      {isLoading && (
        <div
          className={classNames('sl-section-container', styles.content)}
          data-testid="subregion-forecast-page"
        >
          <header className={styles.header}>
            <SubregionForecaster />
            <SubregionForecastButtonList
              isSubregionListVisible={isSubregionListVisible}
              subregions={filteredSubregions}
              activeSubregion={activeSubregion}
            />
          </header>
          <Skeleton
            className={styles.emptyCard}
            animation="wave"
            variant="rectangular"
            width="100%"
          />
        </div>
      )}
      {!isLoading && !forecastContent && (
        <>
          {subregionOverview && (
            <PremiumAnalysisMeta
              subregionName={subregionOverview.name}
              subregionId={subregionOverview._id}
            />
          )}
          <SubregionForecastBackground spotId={primarySpot?._id} geo={defaultGeo} mapZoom={5} />
          <div
            className={classNames('sl-section-container', styles.content)}
            data-testid="subregion-forecast-page"
          >
            <header className={styles.header}>
              <SubregionForecaster />
              <SubregionForecastButtonList
                isSubregionListVisible={isSubregionListVisible}
                subregions={filteredSubregions}
                activeSubregion={activeSubregion}
              />
            </header>
            <Card className={styles.emptyCard} component="section" elevation={0}>
              <Typography
                component="h3"
                variant="headline"
                className={styles.emptyCardTextHeadline}
              >
                We&apos;re sorry!
              </Typography>
              <Typography component="p" variant="body1" className={styles.emptyCardText}>
                Unfortunately, there&apos;s no written forecast for this region.
              </Typography>
            </Card>
            {!hasPremiumAnalysisPermissions && (
              <SubregionForecastContentPaywall
                geo={defaultGeo}
                segmentProperties={segmentPropsLock}
                upgradeUrl={upgradeUrl}
              />
            )}
          </div>
        </>
      )}
      {!isLoading && forecastContent && (
        <div className={classNames({ [styles.wrapper]: true, [styles.isNative]: isNative })}>
          <PremiumAnalysisMeta
            author={forecastContent.forecaster.name}
            headline={forecastContent.today.forecast.headline}
            subregionId={forecastContent.subregion.id}
            subregionName={forecastContent.subregion.name}
            timestamp={forecastContent.lastPublished}
          />
          <SubregionForecastBackground spotId={primarySpot?._id} geo={geo} />
          <div
            className={classNames('sl-section-container', styles.content)}
            data-testid="subregion-forecast-page"
          >
            <header className={styles.header}>
              <SubregionForecaster />
              <SubregionForecastButtonList
                isSubregionListVisible={isSubregionListVisible}
                subregions={filteredSubregions}
                activeSubregion={activeSubregion}
              />
            </header>
            <div id={`forecast-day-${forecastContent.today.date}`}>
              <SubregionForecastCard
                className={styles.card}
                contentBlock="today"
                contentBody={forecastContent.today.forecast.observation}
                contentHeadline={forecastContent.today.forecast.headline}
                date={todayDate}
                forecasterName={forecastContent.forecaster.name}
                isFeedbackActive={hasPremiumAnalysisPermissions || isAfter2pm}
                lastUpdate={lastUpdate}
                pageName="Premium Analysis"
                spotId={spot?._id ?? spotId}
                spotName={spot?.name}
                subregionId={forecastContent.subregion.id}
                subregionName={forecastContent.subregion.name}
                timezone={forecastContent.timezone}
                title={
                  forecastContent.today.forecast.dayToWatch ? (
                    <>
                      <StarCircle className={styles.star} testId="forecast-today-star" /> Today
                    </>
                  ) : (
                    <>Today</>
                  )
                }
                type="today"
                showShareIcons={showShareIcons}
                isNative={isNative}
              >
                {!hasPremiumAnalysisPermissions && (
                  <>
                    {!isAfter2pm && (
                      <Typography component="div" variant="footnote" className={styles.cardText}>
                        Today&apos;s Forecast will be available to you at 2pm.
                      </Typography>
                    )}
                    <Stack direction="row" spacing={1} mt={2} mb={isAfter2pm ? 2 : 0}>
                      <Information
                        DialogFooter={
                          <SubregionForecastContentPaywallDialogFooter
                            segmentProps={segmentPropsLock}
                            upgradeUrl={upgradeUrl}
                          />
                        }
                        articleId={PRODUCT_TUTORIALS.articles.paywallForecasterContent.id}
                        isNative={isNative}
                        theme="cinematic"
                      >
                        <IconButton
                          aria-label="Premium content is locked until 2pm. Upgrade to see it now."
                          className={classNames({
                            [styles.paywallLockButton]: true,
                            [styles.paywallLockButtonUnlocked]: isAfter2pm,
                          })}
                          data-testid="close-button"
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                        >
                          <PadlockIcon locked={!isAfter2pm} />
                        </IconButton>
                      </Information>
                      <Button
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        href={upgradeUrl}
                        size="small"
                        variant="primary"
                      >
                        {isAfter2pm ? 'Get it earlier with Premium' : 'Upgrade to see it now'}
                      </Button>
                    </Stack>
                  </>
                )}
                {(hasPremiumAnalysisPermissions || isAfter2pm) && (
                  <>
                    <Typography
                      component="h3"
                      variant="callout1"
                      className={styles.cardTextHeadline}
                    >
                      {forecastContent.today.forecast.headline}
                    </Typography>
                    <Typography component="div" variant="footnote" className={styles.cardText}>
                      <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: forecastContent.today.forecast.observation,
                        }}
                      />
                    </Typography>
                    {spots}
                  </>
                )}
              </SubregionForecastCard>
            </div>
            <div id={`forecast-day-${forecastContent.tomorrow.date}`}>
              <SubregionForecastCard
                className={styles.card}
                contentBlock="tomorrow"
                contentBody={forecastContent.tomorrow.forecast.observation}
                contentHeadline={forecastContent.tomorrow.forecast.headline}
                date={tomorrowDate}
                forecasterName={forecastContent.forecaster.name}
                isFeedbackActive={hasPremiumAnalysisPermissions}
                lastUpdate={lastUpdate}
                pageName="Premium Analysis"
                spotId={spot?._id ?? spotId}
                spotName={spot?.name}
                subregionId={forecastContent.subregion.id}
                subregionName={forecastContent.subregion.name}
                timezone={forecastContent.timezone}
                title={
                  forecastContent.tomorrow.forecast.dayToWatch ? (
                    <>
                      <StarCircle className={styles.star} testId="forecast-tomorrow-star" />{' '}
                      Tomorrow
                    </>
                  ) : (
                    <>Tomorrow</>
                  )
                }
                type="tomorrow"
                showShareIcons={showShareIcons}
                isNative={isNative}
              >
                {hasPremiumAnalysisPermissions ? (
                  <>
                    <Typography
                      component="h3"
                      variant="callout1"
                      className={styles.cardTextHeadline}
                    >
                      {forecastContent.tomorrow.forecast.headline}
                    </Typography>
                    <Typography component="div" variant="footnote" className={styles.cardText}>
                      <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: forecastContent.tomorrow.forecast.observation,
                        }}
                      />
                    </Typography>
                  </>
                ) : (
                  <Stack direction="row" spacing={1} mt={2}>
                    <Information
                      DialogFooter={
                        <SubregionForecastContentPaywallDialogFooter
                          segmentProps={segmentPropsLock}
                          upgradeUrl={upgradeUrl}
                        />
                      }
                      articleId={PRODUCT_TUTORIALS.articles.paywallForecasterContent.id}
                      isNative={isNative}
                      theme="cinematic"
                    >
                      <IconButton
                        aria-label="Premium content is locked. Upgrade to see it now."
                        className={styles.paywallLockButton}
                        data-testid="close-button"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      >
                        <PadlockIcon />
                      </IconButton>
                    </Information>
                    <Button
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      href={upgradeUrl}
                      size="small"
                      variant="primary"
                    >
                      Upgrade to Premium
                    </Button>
                  </Stack>
                )}
              </SubregionForecastCard>
            </div>
            {!!forecastHighlights.length && (
              <SubregionForecastCard
                className={styles.card}
                contentBlock="highlights"
                date={todayDate}
                forecasterName={forecastContent.forecaster.name}
                isFeedbackActive={hasPremiumAnalysisPermissions}
                lastUpdate={lastUpdate}
                pageName="Premium Analysis"
                spotId={spot?._id ?? spotId}
                spotName={spot?.name}
                subregionId={forecastContent.subregion.id}
                subregionName={forecastContent.subregion.name}
                timezone={forecastContent.timezone}
                title={
                  <>
                    <StarCircle className={styles.star} /> Days to Watch
                  </>
                }
                type="highlights"
                showShareIcons={showShareIcons}
                isNative={isNative}
              >
                <SubregionForecastHighlights days={highlights} />
                <Grid container spacing={2} mt={1}>
                  {highlightsContent}
                  {!hasPremiumAnalysisPermissions && (
                    <Grid item mobile={12} tablet={12}>
                      <Box display="flex">
                        <Button
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          href={upgradeUrl}
                          size="small"
                          variant="primary"
                        >
                          Upgrade to Premium
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </SubregionForecastCard>
            )}
            {!hasPremiumAnalysisPermissions && (
              <SubregionForecastContentPaywall
                geo={geo}
                isNative={isNative}
                segmentProperties={segmentPropsLock}
                upgradeUrl={upgradeUrl}
              />
            )}
          </div>
        </div>
      )}
    </Box>
  );
};

export default SubregionForecast;
